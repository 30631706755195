import React from 'react'
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const HerImage = styled(Img)`
  width: 100%;
  max-height: 60vh;
  height: 60vh;
  /* object-position: 0px 0px; */
  margin-bottom: ${props => props.theme.space[12]};

  @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    margin-top: ${props => props.theme.space[8]};
  }
`

export default function AboutMeHeroImage ({path}) {
  return (
    <StaticQuery
      query={graphql`
        query AboutMeHeroImage {
          image: sanityAboutme {
            id
            aboutemeheaderimage {
              asset {
                fluid(maxHeight: 550, maxWidth: 1072) {
                  src
                  srcSet
                }
              }
            }
          }
        }`}
      render={data => (
        // <p>C{Cconsole.log(data.image)}</p>
        <HerImage fluid={data.image.aboutemeheaderimage ? data.image.aboutemeheaderimage.asset.fluid : null} alt='ueber mich' />
      )}
    />
  )
}
