import React from 'react'
import {graphql} from 'gatsby'
import Container from '../styles/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Grid from '../components/about_me/aboutme-grid'
import HeroImage from '../components/about_me/aboutme-image'


const AboutMe = props => {
  return (
    <Layout>
      <SEO
        title="Über mich" />
      <HeroImage />
      <Container>
        <Grid />
      </Container>
    </Layout>
  )
}

export default AboutMe
