import React from 'react'
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const MainImage = styled(Img)`
  max-width: 100%;
  padding: ${props => props.theme.space[5]};
  border: 12px solid white;
  min-height: 500px;
`

export default function AboutMeHeroImage ({path}) {
  return (
    <StaticQuery
      query={graphql`
        query AboutMeMainImage {
          header: sanityAboutme {
            id
            belowheaderimage {
              asset {
                fluid(maxHeight: 900, maxWidth: 600) {
                  src
                  srcSet
                }
              }
            }
          }
        }`}
      render={data => (
        <MainImage fluid={data.header.belowheaderimage ? data.header.belowheaderimage.asset.fluid : null}
          alt='carolin vollmer' />
      )}
    />
  )
}
