import React from 'react'
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const MainImage = styled(Img)`
  max-width: 100%;
  padding: ${props => props.theme.space[5]};
  grid-area: imagethree;
  border: 12px solid white;
  height: 500px;
`

export default function AboutMeThirdImage () {
  return (
    <StaticQuery
      query={graphql`
        query AboutMeThirdImage {
          image: sanityAboutme {
            id
            lastimage {
              asset {
                fluid(maxHeight: 400, maxWidth: 400) {
                  src
                  srcSet
                }
              }
            }
          }
        }`}
      render={data => (
        <MainImage fluid={data.image.lastimage ? data.image.lastimage.asset.fluid : null} alt='carolin vollmer' />
      )}
    />
  )
}
