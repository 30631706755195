import React from 'react'
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const MainImage = styled(Img)`
  max-width: 100%;
  padding: ${props => props.theme.space[5]};
  grid-area: imagetwo;
  border: 12px solid white;
  min-height: 400px;
`

export default function AboutMeSecondImage () {
  return (
    <StaticQuery
      query={graphql`
        query AboutMeSecondImage {
          image: sanityAboutme {
            id
            inspirationimage {
              asset {
                fluid(maxHeight: 400, maxWidth: 400) {
                  src
                  srcSet
                }
              }
            }
          }
        }`}
      render={data => (
        <MainImage fluid={data.image.inspirationimage ? data.image.inspirationimage.asset.fluid : null} alt='blumen' />
      )}
    />
  )
}
