import React from 'react';
import styled from 'styled-components'
import { StaticQuery, graphql, Link} from "gatsby"
import CaroImage from './aboutme-grid-mainimage'
import SecondImage from './aboutme-grid-secondimage'
import ThirdImage from './aboutme-grid-thirdimage'
import BlockContent from '@sanity/block-content-to-react'
import { Parallax  } from 'react-scroll-parallax';

const GridStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(30vh, auto) auto;
  grid-gap: ${props => props.theme.space[7]};
  grid-template-areas: "caroimage"
                       "willkommen"
                       "inspiration"
                       "imagetwo"
                       "leidenschaft"
                       "imagethree"
                       "howiwork"
                       "mygoal";
  margin-top: -20vh;
  margin-bottom: 20vh;

  @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(10vh, auto) auto;
    grid-gap: ${props => props.theme.space[7]};
    grid-template-areas: "caroimage ."
                        "caroimage willkommen"
                        "inspiration willkommen"
                        "inspiration imagetwo"
                        "leidenschaft imagetwo"
                        "imagethree howiwork"
                        "imagethree mygoal";
  }

  p {
    /* font-size: ${props => props.theme.fontSizes[1]}; */
  }

  .parallax-outer {
    margin: 0;
  }
`;

const Willkommen = styled.div`
    grid-area: willkommen;
`;

const ParallaxMainImage = styled(Parallax)`
  grid-area: caroimage; 
`;

const Inspiration = styled.div`
    grid-area: inspiration;
`;

const Leidenschaft = styled.div`
    grid-area: leidenschaft;
`;

const HowIWork = styled.div`
    grid-area: howiwork;
`;
 
const MyGoal = styled.div`
    grid-area: mygoal;
`;


const Grid = props => {
  return (
    <StaticQuery
      query={graphql`
        query AboutMeTexts {
          aboutme: sanityAboutme(_id: { regex: "/(drafts.|)aboutme/" }) {
            willkommenueberschrift
            inspirationueberschrift
            inbetweenheading
            howiworkheading
            goalheading
            _rawGoaltext(resolveReferences: {maxDepth: 10})
            _rawHowiworktext(resolveReferences: {maxDepth: 10})
            _rawInspirationtext(resolveReferences: {maxDepth: 10})
            _rawWillkommentext(resolveReferences: {maxDepth: 10})
          }
        }`}
      render={data => (
        <GridStyled>
          <ParallaxMainImage className="custom-class" y={[-15, 8]} tagOuter="figure">
            <CaroImage />
          </ParallaxMainImage>
          
          <SecondImage />
          <ThirdImage />

          <Willkommen>
            <h2>{data.aboutme.willkommenueberschrift}</h2>
            <BlockContent blocks={data.aboutme._rawWillkommentext} />
          </Willkommen>

          <Inspiration>
            <h2>{data.aboutme.inspirationueberschrift}</h2>
            <BlockContent blocks={data.aboutme._rawInspirationtext} />
          </Inspiration>

          <Leidenschaft>
            <h2>{data.aboutme.inbetweenheading}</h2>
          </Leidenschaft>
          
          <HowIWork>
            <h2>{data.aboutme.howiworkheading}</h2>
            <BlockContent blocks={data.aboutme._rawHowiworktext} />
          </HowIWork>

          <MyGoal>
            <h2>{data.aboutme.goalheading}</h2>
            <BlockContent blocks={data.aboutme._rawGoaltext} />
          </MyGoal>

        </GridStyled>
    )}
    />
  )
}

export default Grid